<template>
  <section>
    <div>
      <a-table ref="tableRef" :columns="columns" :data-source="tableData" rowKey="id"
               :scroll="{ y: tableHeight, x: 1200 }" :pagination="pagination">
        <template #fileName="{ record }">
          <div style="position: relative">
            <span v-show="record.mark === '0'" class="mark">●</span>
            <span>{{record.fileName}}</span>
          </div>
        </template>
        <template #status="{ record }">
          <a-tag :class="`status ${record.status}`">
            {{record.status}}
          </a-tag>
        </template>
        <template #action="{ record }">
          <ActionBtn v-bind="record" :btnList="btnList" @ExceptionOutlined="abnormalInformationClick(record)"/>
        </template>
      </a-table>
    </div>
    <ErrorInformationModal
        :visible="errorVisible"
        :errorInfo="errorInfo"
        @close-modal="closeErrorModal"
    />
  </section>
</template>

<script>
import ActionBtn from "@/components/ActionBtn.vue";
import {defineComponent, nextTick, onMounted, reactive, ref} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import ErrorInformationModal from "@/views/home/FileTransferStation/UploadTask/components/ErrorInformationModal.vue";
import store from "@/store";

const btnList = [
  {
    name: '异常信息',
    icon: 'ExceptionOutlined',
    auth: ['system:download:task:abnormal']
  },
]

export default defineComponent({
  name: "index",
  components: {ErrorInformationModal, ActionBtn},
  setup() {
    const { $api } = useGlobalPropertyHook();
    const tableHeight = ref(0);
    const tableData = ref([])

    const getSize = () => {
      nextTick(() => {
        const clientHeight = document.body.clientHeight;
        tableHeight.value = (clientHeight * 69) / 100;
      });
    };

    const columns = [
      {
        title: "文件类型",
        dataIndex: "fileType",
        key: "fileType",
        ellipsis: true,
        width: 200,
      },
      {
        title: "任务名称",
        dataIndex: "fileName",
        key: "fileName",
        ellipsis: true,
        width: 200,
        slots: { customRender: "fileName" },
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        key: "createTime",
        ellipsis: true,
        width: 200,
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        slots: { customRender: "status" },
        width: 200,
      },
      {
        title: "备注",
        dataIndex: "notes",
        key: "notes",
        ellipsis: true,
        width: 300,
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        slots: { customRender: "action" },
        width: 100,
        fixed: "right",
      },
    ]

    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getTableData();
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getTableData();
      },
    });

    const getTableData = () => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getUploadTask(params).then((res) => {
        pagination.total = res.data.total;
        tableData.value = res.data.resource;
        //判断文件中转站是否有未读消息
        store.dispatch("getIsFileMessage");
      });
    };
    const doSearch = () => {
      pagination.current = 1;
      getTableData()
    };

    const errorVisible = ref(false)
    const errorInfo = ref({})
    const closeErrorModal = () => {
      errorVisible.value = false
    }
    const abnormalInformationClick = (record) => {
      errorVisible.value = true
      errorInfo.value = record
    }


    onMounted(() => {
      //doSearch()
      getSize();
    });
    return {
      tableHeight,
      getSize,
      columns,
      tableData,
      pagination,
      getTableData,
      abnormalInformationClick,
      btnList,
      doSearch,
      errorVisible,
      errorInfo,
      closeErrorModal,
    };
  }
})
</script>

<style scoped lang="less">
.icon {
  font-size: 24px;
  color: #3182CE;
}
.redIcon {
  color: #DC5E4F;
}
.status{
  font-size: 0.729vw;
  padding: 0.1vw 0.86vw;
  border: 0;

  &.处理中 {
    color: #1890FF;
    background: #DBEEFF;
  }
  &.处理完成 {
    color: #58B99E;
    background: #DDF8F0 60%;
  }
  &.已过期 {
    color: #999999;
    background: #CCCCCC 60%;
  }
  &.处理失败 {
    color: #DC5E4F;
    background: #FFE7EC;
  }
}
.mark {
  color: #DC5E4F;
  position: absolute;
  left: -0.7vw;
}
</style>
