<template>
  <section>
    <div>
      <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" @doCollapseEffect="getSize">
        <template #customform>
          <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
            <a-form-item label="文件类型" name="fileType" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.fileType"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  :options="FileTypeOps"
              >
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="创建时间" name="date" class="rms-form-item">
              <a-range-picker
                  v-model:value="formState.date"
                  :placeholder="['起始时间', '终止时间']"
              />
            </a-form-item>
            <a-form-item label="状态" name="status" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.status"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  :options="statusOps"
              >
              </SelectWithAll>
            </a-form-item>
          </a-form>
        </template>
      </FilterLayout>
    </div>
    <div>
      <a-table ref="tableRef" :columns="columns" :data-source="tableData" rowKey="id"
               :scroll="{ y: tableHeight, x: 1000 }" :pagination="pagination">
        <template #fileName="{ record }">
          <div style="position: relative">
            <span v-show="record.mark === '0'" class="mark">●</span>
            <span>{{record.fileName}}</span>
          </div>
        </template>
        <template #status="{ record }">
          <a-tag :class="`status ${record.status}`">
            {{record.status}}
          </a-tag>
        </template>
        <template #action="{ record }">
          <ActionBtn v-bind="record" :btnList="btnList" @DownloadOutlined="downloadClick(record)" />
        </template>
      </a-table>
    </div>
  </section>
</template>

<script>

import {defineComponent, nextTick, onMounted, reactive, ref} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {cloneDeep} from "lodash";
import {dictionaryOpt, downloadFile, useTableHeight} from "@/utils/common";
import {message} from "ant-design-vue";
import ActionBtn from "@/components/ActionBtn.vue";
import store from "@/store";

const btnList = [
  {
    name: '下载',
    icon: 'DownloadOutlined',
    auth: ['system:download:task:export']
  },
]

export default defineComponent({
  name: "index",
  components: {ActionBtn},
  setup() {
    let initData = {
      fileType: [],
      status: [],
      date: [],
      startDate: "",
      endDate: "",
    }
    const { $api } = useGlobalPropertyHook();
    const formState = reactive({...initData});
    const formRef = ref();
    let currentState = {}
    const tableHeight = ref(0);
    const tableData = ref([])
    const FileTypeOps = ref([])
    const statusOps = ref([])

    const resetForm = () => {
      Object.assign(formState,initData)
      formRef.value.resetFields();
      updateParams()
      doSearch();
    };

    const updateParams =() => {
      currentState = cloneDeep(formState);
    }

    const doSearch = () => {
      pagination.current = 1;
      updateParams()
      getTableData(currentState)
    };
    // const resetSearch = () => {
    //   updateParams()
    //   getTableData(currentState)
    // };

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    const columns = [
      {
        title: "文件类型",
        dataIndex: "fileType",
        key: "fileType",
        ellipsis: true,
        width: 200,
      },
      {
        title: "文件名称",
        dataIndex: "fileName",
        key: "fileName",
        ellipsis: true,
        width: 400,
        slots: { customRender: "fileName" },
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        key: "createTime",
        ellipsis: true,
        width: 300,
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        slots: { customRender: "status" },
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        slots: { customRender: "action" },
        width: 120,
        fixed: "right",
      },
    ]

    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getTableData(currentState);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getTableData(currentState);
      },
    });

    const getTableData = (filterData) => {
      const params = {
        ...filterData,
        startDate: filterData.date[0]?.format("YYYY-MM-DD"),
        endDate: filterData.date[1]?.format("YYYY-MM-DD"),
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getDownloadTask(params).then((res) => {
        pagination.total = res.data.total;
        tableData.value = res.data.resource;
        //判断文件中转站是否有未读消息
        store.dispatch("getIsFileMessage");
      });
    };
    const getOptions = () => {
      $api.getTaskTypeSelect().then((res) => {
        FileTypeOps.value = dictionaryOpt(res.data);
      });
      statusOps.value = dictionaryOpt(["处理完成","处理中","处理失败","已过期"]);
    }



    const downloadClick = (record) => {
      $api.downloadCVs(record.relationId).then((res) => {
        downloadFile(res.file, record.fileName);
        message.success("下载成功")
      });
    }


    onMounted(() => {
      doSearch();
      getSize();
      getOptions()
    });
    return {
      formState,
      formRef,
      resetForm,
      doSearch,
      tableHeight,
      getSize,
      columns,
      tableData,
      pagination,
      getTableData,
      FileTypeOps,
      statusOps,
      downloadClick,
      btnList,
    };
  }
})
</script>

<style scoped lang="less">
.icon {
  font-size: 24px;
  color: #3182CE;
}
.redIcon {
  color: #DC5E4F;
}
.status{
  font-size: 0.729vw;
  padding: 0.1vw 0.86vw;
  border: 0;

  &.处理中 {
    color: #1890FF;
    background: #DBEEFF;
  }
  &.处理完成 {
    color: #58B99E;
    background: #DDF8F0 60%;
  }
  &.已过期 {
    color: #999999;
    background: #CCCCCC 60%;
  }
  &.处理失败 {
    color: #DC5E4F;
    background: #FFE7EC;
  }
}
.mark {
  color: #DC5E4F;
  position: absolute;
  left: -0.7vw;
}
</style>
