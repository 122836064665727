<template>
  <div ref="errorInformationRef">
    <a-modal
        :getContainer="()=>$refs.errorInformationRef"
        :visible="visible"
        @cancel="closeModal"
        width="62.5vw"
        centered
        title="异常信息"
        class="errorInformation"
    >
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closeModal">取消</a-button>
        </div>
      </template>
      <a-table
          :data-source="tableData"
          :columns="columns"
          rowKey="id"
          :scroll="{
            x:700,
            y: tableHeight,
            scrollToFirstRowOnChange: true,
          }"
          :pagination="pagination"
      >
        <template #line="{ record }">
          <span style="white-space: pre-wrap">第{{record.line}}行</span>
        </template>
        <template #errorMsg="{ record }">
          <span style="white-space: pre-wrap">{{record.errorMsg}}</span>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import {useGlobalPropertyHook} from "@/hooks/common";
import {defineComponent, nextTick, onMounted, reactive, ref, watch} from "vue";

export default defineComponent({
  name: "ErrorInformationModal",
  props: {
    visible: Boolean,
    errorInfo: Object,
  },
  emits: ["close-modal"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const columns = ref([
      {
        title: "行数",
        dataIndex: "line",
        ellipsis: true,
        width: 100,
        slots: { customRender: "line" },
      },
      {
        title: "内容",
        dataIndex: "errorMsg",
        slots: { customRender: "errorMsg" },
      },
    ]);
    const tableData = ref([]);
    const getTableData = () => {
      $api.getErrorMsg(props.errorInfo.id,pagination.current,pagination.pageSize).then((res) => {
        pagination.total = res.data.total;
        tableData.value = res.data.resource;
      });
    };
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getTableData();
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getTableData();
      }
    });
    watch(
        () => props.visible,
        (val) => {
          if (val) {
            getTableData();
          }
        }
    );

    const closeModal = () => {
      tableData.value = []
      pagination.current = 1;
      pagination.pageSize = 10;
      ctx.emit("close-modal");
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const tableHeight = ref(0);

    onMounted(() => {
      nextTick(() => {
        const clientHeight = document.body.clientHeight;
        tableHeight.value = (clientHeight * 60) / 100;
      });
    });

    return {
      columns,
      tableData,
      getRefsAff,
      closeModal,
      tableHeight,
      pagination,
    };
  },
})
</script>

<style scoped lang="less">

</style>
