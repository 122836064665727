<template>
  <section class="file-transfer">
    <div>
      <a-tabs v-model:value="activeTab" @change="tabChange">
        <a-tab-pane v-for="{ name, title } in tabList" :key="name" :tab="title"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="app-container">
      <DownloadTask ref="downloadRef" v-show="activeTab === 'DownloadTask'" v-auth="['system:download:task']"/>
      <UploadTask ref="uploadRef" v-show="activeTab === 'UploadTask'" v-auth="['system:upload:task']"/>
    </div>
  </section>
</template>

<script>
import {defineComponent, onActivated, ref} from "vue";
import DownloadTask from "@/views/home/FileTransferStation/DownloadTask/index.vue";
import UploadTask from "@/views/home/FileTransferStation/UploadTask/index.vue";

export default defineComponent({
  name: "index",
  components: {
    DownloadTask,
    UploadTask,
  },
  setup(props, ctx) {
    const activeTab = ref("DownloadTask");
    const downloadRef = ref();
    const uploadRef = ref();

    const tabChange = (value) => {
      activeTab.value = value
      if(value === 'DownloadTask') {
        downloadRef.value.doSearch()
      } else {
        uploadRef.value.doSearch()
      }
    };

    const tabList = ref([
      {
        name: "DownloadTask",
        title: "下载任务",
      },
      {
        name: "UploadTask",
        title: "上传任务",
      },
    ]);

    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      tabList,
      downloadRef,
      uploadRef,
      activeTab,
      tabChange,
    };
  },
});
</script>

<style scoped lang="less">
@import "~@/style/newTitleTab.less";
.file-transfer {
  height: calc(100% - 2.08333vw);
}
</style>
